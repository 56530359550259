import classNames from 'classnames';
import React from 'react';
import { LibraryCreator } from '../../types';
import styles from './LibraryCreatorPicture.module.scss';

interface LibraryCreatorPictureProps
  extends Pick<LibraryCreator, 'kind' | 'name' | 'picture'> {
  small?: boolean;
}

const LibraryCreatorPicture: React.SFC<LibraryCreatorPictureProps> = ({
  kind,
  name,
  picture,
  small = false,
}) => (
  <img
    className={classNames(styles.picture, {
      [styles.circular]: kind === 'individual',
      [styles.small]: small,
    })}
    src={picture.publicURL}
    alt={name}
  />
);

export default LibraryCreatorPicture;
