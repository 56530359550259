import { navigate } from 'gatsby';
import { SyntheticEvent } from 'react';
import useLoggedInTeams from '../../utils/useLoggedInTeams';
import getTemplateUrl from './getTemplateUrl';

const useOpenTemplate = (slug: string, onEmptyTeams: () => void) => {
  const teams = useLoggedInTeams();

  return (event: SyntheticEvent) => {
    event.preventDefault();
    if (teams?.length) {
      navigate(getTemplateUrl(slug));
    } else {
      onEmptyTeams?.();
    }
  };
};

export default useOpenTemplate;
