import { Link } from 'gatsby';
import React, { useState } from 'react';
import browserNavigationIcon from '../../images/library/browserNavigation.svg';
import { LibraryPost } from '../../types';
import SignUpModal from '../signUpModal/SignUpModal';
import Button from '../ui/Button';
import LibraryBreadcrumbs from './LibraryBreadcrumbs';
import LibraryCreatorName from './LibraryCreatorName';
import LibraryCreatorPicture from './LibraryCreatorPicture';
import styles from './LibraryPostPage.module.scss';
import LibraryPostsList from './LibraryPostsList';
import getTemplateUrl from './getTemplateUrl';
import useOpenTemplate from './useOpenTemplate';

interface LibraryPostPageProps {
  post: LibraryPost;
}

const LibraryPostPage = ({ post }: LibraryPostPageProps) => {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState<boolean>(false);

  const openTemplate = useOpenTemplate(post.slug, () =>
    setIsSignUpModalOpen(true),
  );

  const creatorContents = (
    <div className={styles.creatorContainer}>
      <LibraryCreatorPicture small {...post.creator} />
      <div className={styles.creatorName}>
        <LibraryCreatorName {...post.creator} />
      </div>
    </div>
  );

  const relatedPosts = post.related || [];
  const relatedTitle =
    post.collection && post.collection.posts.length > 1
      ? `Related examples in ${post.collection.name}`
      : 'Related examples';
  const collectionPosts = post.collection?.posts || [];
  if (!post.related && collectionPosts.length > 1) {
    const index = collectionPosts.findIndex((p) => p.slug === post.slug) || 0;
    const prevIndex = index > 0 ? index - 1 : collectionPosts.length - 1;
    const nextIndex = (index + 1) % collectionPosts.length;
    relatedPosts.push(collectionPosts[prevIndex]);
    relatedPosts.push(collectionPosts[nextIndex]);
  }

  const description = (
    <div
      className={styles.longDescription}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: post.longDescription || post.description,
      }}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div>
          <div className={styles.back}>
            <LibraryBreadcrumbs
              category={post.category}
              collection={post.collection}
            />
          </div>
          {post.creator.url ? (
            <a href={post.creator.url} target="_blank" rel="noreferrer">
              {creatorContents}
            </a>
          ) : (
            creatorContents
          )}
          <h1 className={styles.title}>{post.title}</h1>
          <div className={styles.desktopLongDescription}>{description}</div>
        </div>
        {relatedPosts.length > 0 && (
          <div className={styles.desktopRelated}>
            <div className={styles.relatedHeader}>{relatedTitle}</div>
            <LibraryPostsList posts={relatedPosts} card />
            {post.collection && (
              <div className={styles.relatedMore}>
                <Link to={post.collection.url}>
                  View all templates in {post.collection.name}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.template}>
        <div className={styles.header}>
          <div className={styles.navigationIcon}>
            <img
              height={16}
              src={browserNavigationIcon}
              alt=""
              role="presentation"
            />
          </div>
          <div className={styles.templateButton}>
            <Button unstyled onClick={openTemplate}>
              Use template
            </Button>
          </div>
        </div>
        <div className={styles.pageSheet}>
          <div
            className={styles.content}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>

      <div className={styles.mobileBottomContent}>
        <div className={styles.mobileLongDescription}>{description}</div>
        {relatedPosts.length > 0 && (
          <>
            <div className={styles.relatedHeader}>{relatedTitle}</div>
            <LibraryPostsList posts={relatedPosts.slice(0, 3)} />
          </>
        )}
      </div>
      {isSignUpModalOpen && (
        <SignUpModal
          onClose={() => setIsSignUpModalOpen(false)}
          loginUrl={getTemplateUrl(post.slug)}
        />
      )}
    </div>
  );
};

export default LibraryPostPage;
