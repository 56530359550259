import React from 'react';
import { LibraryCreator } from '../../types';
import styles from './LibraryCreatorName.module.scss';

type LibraryCreatorNameProps = Pick<LibraryCreator, 'name' | 'color'>;

const LibraryCreatorName: React.SFC<LibraryCreatorNameProps> = ({
  name,
  color,
}) => (
  <div className={styles.container} style={{ color }}>
    {name}
  </div>
);

export default LibraryCreatorName;
