import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/Layout';
import Meta from '../components/layout/Meta';
import NavBar from '../components/layout/NavBar';
import UntitledStyle from '../components/layout/UntitledStyle';
import LibraryPostPage from '../components/library/LibraryPostPage';
import { LibraryPostsYaml } from '../graphql-types';
import { toLibraryPost } from '../utils/toLibraryType';
import styles from './LibraryPostTemplate.module.scss';

interface LibraryPostTemplateProps {
  data: {
    post: LibraryPostsYaml;
  };
}

const LibraryPostTemplate: React.SFC<LibraryPostTemplateProps> = ({
  data: { post },
}) => {
  const postForPage = toLibraryPost(post);
  const canonicalPath = postForPage.path;

  return (
    <Layout>
      <Meta
        title={`${postForPage.title} by ${postForPage.creator.name} - Slab Library`}
        canonicalPath={canonicalPath}
      />
      <div className={styles.nav}>
        <NavBar showRegister noPadding />
      </div>
      <UntitledStyle>
        <div className={styles.content}>
          <LibraryPostPage post={postForPage} />
        </div>
      </UntitledStyle>
    </Layout>
  );
};

export const query = graphql`
  query LibraryPostPageQuery($slug: String!) {
    post: libraryPostsYaml(fields: { slug: { eq: $slug } }) {
      title
      description
      longDescription
      url
      html
      type
      category {
        name
        fields {
          slug
        }
      }
      collection {
        name
        slug
        posts {
          title
          type
          category {
            name
            fields {
              slug
            }
          }
          creator {
            kind
            name
            picture {
              publicURL
            }
            color
          }
          fields {
            slug
          }
        }
      }
      creator {
        color
        kind
        name
        picture {
          publicURL
        }
        url
      }
      fields {
        slug
      }
      related {
        title
        type
        category {
          name
          fields {
            slug
          }
        }
        creator {
          kind
          name
          picture {
            publicURL
          }
          color
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default LibraryPostTemplate;
